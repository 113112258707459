export const emojiFullList = [
  "💩",
  "👻",
  "👾",
  "🤖",
  "🧠",
  "🍄",
  "🍁",
  "🔥",
  "❄",
  "🌈",
  "🍎",
  "🍇",
  "🌶",
  "🍔",
  "🍕",
  "🍪",
  "⚽",
  "👺",
  "🙀",
  "🙋🏻‍",
  "🦄",
  "🧟‍♀️",
  "🐝",
  "🐙",
  "🇨🇦",
  "🔮",
];
